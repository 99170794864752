import type { Config } from "@twilio/flex-ui";

const appConfig: Config = {
    pluginService: {
        enabled: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // The following property seems to work, even though it doesn't seem
        // to be document by Twilio Flex types ...
        url: "/plugins.json",
    },
    sso: {
        accountSid: "AC33274c3c6802b1a76bca2df04dad494a",
    },
    logLevel: "info",
};

export default appConfig;
