import React from "react";
import ReactDOM from "react-dom";
import * as Flex from "@twilio/flex-ui";
import * as Sentry from "@sentry/react";

import appConfig from "./appConfig";

type Props = {
    manager: Flex.Manager;
};

export function App({ manager }: Props) {
    return (
        <Flex.ContextProvider manager={manager}>
            <Flex.RootContainer />
        </Flex.ContextProvider>
    );
}

export function loadApp() {
    initSentry();

    const mountNode = document.getElementById("root");
    if (!mountNode) {
        Sentry.captureException("Cannot render Flex: mountNode not found");
        return;
    }

    ensureSingleTab(mountNode);

    Flex.progress(mountNode)
        .provideLoginInfo(appConfig, mountNode)
        .then(() => Flex.Manager.create(appConfig))
        .then((manager) => {
            setCountryTag(manager);
            renderApp(manager, mountNode);
            refreshToken(manager);
        })
        .catch((error) => handleError(error, mountNode));
}

function renderApp(manager: Flex.Manager, mountNode: HTMLElement) {
    ReactDOM.render(<App manager={manager} />, mountNode);
}

function renderErrorMulitpleTabs(mountNode: HTMLElement) {
    ReactDOM.render(
        <div className="error-multiple-tabs-view">
            <p>
                You can only have 1 Flex tab open in your browser!
                <br />
                <br />
                Please reuse the existing tabs or refresh this page to use this
                tab.
            </p>
        </div>,
        mountNode
    );
}

function handleError(error: Error, mountNode: HTMLElement) {
    Sentry.captureException(error);
    Flex.errorPage(error, mountNode);
}

function ensureSingleTab(mountNode: HTMLElement) {
    const BROADCAST_CHANNEL = "__flex-wave-broadcast-channel__";
    const BROADCAST_CHANNEL_MESSAGE_NEW_TAB = "__flex-wave-new-tab__";

    const bc = new BroadcastChannel(BROADCAST_CHANNEL);
    bc.onmessage = function onmessage(event: MessageEvent<{ type: string }>) {
        if (event.data.type === BROADCAST_CHANNEL_MESSAGE_NEW_TAB) {
            // FIXME(antonio): Ideally we would like to close existing tabs or
            // focus them. Unfortunately, neither `close()` nor `focus()` seem
            // to be working ...
            // Fallback is to replace the rendered HTML with an error message
            // for now, with the main drawback being that opening a new tab
            // wipes the state of other existing tabs
            renderErrorMulitpleTabs(mountNode);
        }
    };

    // Only send broadcast message if tab isn't login window.
    const searchParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // FIXME(antonio): types don't know about URLSearchParams.keys() ...
    if (!Array.from(searchParams.keys()).includes("Token")) {
        bc.postMessage({
            type: BROADCAST_CHANNEL_MESSAGE_NEW_TAB,
        });
    }
}

function refreshToken(manager: Flex.Manager) {
    setInterval(() => {
        const flexSDKManager = (window as any).FlexSDKManager;
        if (flexSDKManager === undefined) {
            Sentry.captureException(
                "Custom Wave export with Flex SDK manager not found - could not run periodic token refresh."
            );
        } else {
            const ssoToken =
                manager.store.getState().flex.session.ssoTokenPayload?.token;
            if (!ssoToken) {
                Sentry.captureException(
                    "could not find SSO token for Wave periodic refresh"
                );
            } else if (flexSDKManager.updateToken === undefined) {
                Sentry.captureException(
                    "could not find updateToken function on flex SDK manager"
                );
            } else {
                flexSDKManager.updateToken(ssoToken);
            }
        }
    }, 120 * 1000);
}

function initSentry() {
    const IS_PROD = window.location.hostname === "flex.wave.com";

    Sentry.init({
        dsn: "https://1b4807d79cb44167a62db16964795e27@o407766.ingest.sentry.io/4504837467799552",
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.2,
        environment: IS_PROD ? "prod" : "dev",
    });
}

function setCountryTag(manager: Flex.Manager) {
    const countries: Array<string> =
        manager.store.getState().flex.worker.attributes?.countries;
    if (countries?.length) {
        Sentry.setTag("country", countries[0]);
    }
}
